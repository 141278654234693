import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Suspense, lazy } from 'react';
const Home = lazy(() => import('./dynamics/defs/H1mi426632501/H11mi0trW1'));
function App() {
  return (
    <Router>
    <div className='app-main'>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path='/' element={<Home />} />
        </Routes>
      </Suspense>
    </div>
  </Router>
  );
}

export default App;
